import React, { useState } from "react"
import PropTypes from "prop-types"
import { connect as connectFela } from "react-fela"
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import { useSpring, animated } from 'react-spring'

import H5 from "components/typography/H5"
import P from "components/typography/P"
import colors from "theme/colors"
import fluidValues from "utilities/fluidValues"
import TextInput from "components/TextInput"
import SubmitButton from "components/SubmitButton"

const isEmailAddress = (value) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(value).toLowerCase());
}

export const SignupForm = ({ rules, styles, image, title, thankYouMessage, dark, cmListID, onSubmission }) => {
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    name: { value: '', error: null },
    role: { value: '', error: null },
    email: { value: '', error: null },
    organisation: { value: '', error: null },
  })
  const handleChange = (event) => {
    const input = event.target
    setData((data) => {
      const field = {}
      field[input.name] = { value: input.value, error: '' }
      return {...data, ...field}
    })
  }
  const resultProps = useSpring({
    opacity: (submitted ? 1 : 0 )
  })

  const handleSubmit = (event) => {
    event.preventDefault()

    // validate required, email format
    let errored = false

    if (data['name'] !== undefined && !data['name'].value) {
      errored = true
      setData(data => ({...data, ...{ name: { value: data['name'].value, error: "Sorry, we'd like to know this..." }}}))
    }

    if (data['email'] !== undefined && !data['email'].value) {
      errored = true
      setData(data => ({...data, ...{ email: { value: data['email'].value, error: "Sorry, we'd like to know this..." }}}))
    }

    if (data['role'] !== undefined && !data['role'].value) {
      errored = true
      setData(data => ({...data, ...{ role: { value: data['role'].value, error: "Sorry, we'd like to know this..." }}}))
    }

    if (data['organisation'] !== undefined && !data['organisation'].value) {
      errored = true
      setData(data => ({...data, ...{ organisation: { value: data['organisation'].value, error: "Sorry, we'd like to know this..." }}}))
    }

    if (data['email'] !== undefined && !isEmailAddress(data['email'].value)) {
      errored = true
      setData(data => ({...data, ...{ email: { value: data['email'].value, error: "Are you sure? That email address doesn't seem quite right..." }}}))
    }

    if (errored) {
      return false
    }

    setLoading(true)

    const payload= Object.keys(data).reduce((acc, field) => {
      acc[field] = data[field].value
      return acc
    }, {})
    payload['cmListID'] = cmListID

    fetch('/.netlify/functions/subscribe-form', {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload)
    }).then(data => {
      setLoading(false)
      setSubmitted(true)
      onSubmission()
      })
      .catch((error) => {
        setLoading(false)
        setError("Something's not right with the info you've provided. Please try again...")
      })
  }

  return (<div>
    {image && <Img image={image.asset.gatsbyImageData} alt={""} />}
    <div className={styles.container}>
    {title && <H5 extend={{styles: rules.title}}>{title}</H5>}
    {submitted && <animated.div className={styles.result} style={resultProps}>
        <P>{thankYouMessage}</P>
     </animated.div>}
    <form noValidate method={'POST'} onSubmit={(e) => handleSubmit(e)} className={styles.form}>
      <TextInput onChange={(e) => handleChange(e)} title={'Firstname Lastname'} name={'name'} value={data.name.value} error={data.name.error} required />
      <TextInput onChange={(e) => handleChange(e)} title={'Role'} name={'role'} value={data.role.value} error={data.role.error} required />
      <TextInput onChange={(e) => handleChange(e)} title={'Email'} name={'email'} value={data.email.value} error={data.email.error} required email />
      <TextInput onChange={(e) => handleChange(e)} title={'Organisation'} name={'organisation'} value={data.organisation.value} error={data.organisation.error} required />
      <SubmitButton dark={dark} extend={{styles: rules.button}} name={"Submit"} loading={loading} />
      {!error && <div>{error}</div>}
    </form>
    </div>
  </div>)
}

/*
 * color={dark ? "#FFFFFF" : null} bgColor={dark ? colors.colorConsole500 : null}
 */
const styles = (props) => ({
  container: {
    position: 'relative',
    backgroundColor: props.dark ? colors.colorConsole500 : colors.colorCanvas100,
    ...fluidValues({
      paddingLeft: [24, 80],
      paddingRight: [24, 80],
      paddingBottom: [48, 90]
    }),
    phoneOnly: {
      paddingTop: 12,
    }
  },
  title: {
    textAlign: 'center',
    color: colors.colorConsole500,
    borderColor: colors.colorConsole500,
    extend: {
      condition: props.dark,
      style: {
        color: '#FFFFFF',
        borderColor: '#FFFFFF',
      }
    },
    paddingTop: 32,
  },
  button: {
    margin: '0 auto',
    width: '100%',
    backgroundColor: '#FFFFFF',
  },
  form: {
    ...fluidValues({
      paddingTop: [0, 50],
    }),

    color: colors.colorConsole500,
    borderColor: colors.colorConsole500,
    "& input[type='text']": {
      color: colors.colorConsole500,
      onFocus: {
        color: colors.colorConsole500,
      }
    },
    "& input[type='email']": {
      color: colors.colorConsole500,
      onFocus: {
        color: colors.colorConsole500,
      }
    },

    extend: {
      condition: props.dark,
      style: {
        color: '#FFFFFF',
        borderColor: '#FFFFFF',
        "& input[type='text']": {
          color: '#FFFFFF',
          onFocus: {
          color: '#FFFFFF',
          }
        },
        "& input[type='email']": {
          color: '#FFFFFF',
          onFocus: {
          color: '#FFFFFF',
          }
        }
      }
    }
  },
  result: {
    alignItems: 'center',
    backgroundColor: props.dark ? colors.colorConsole500 : colors.colorCanvas100,
    boxSizing: 'border-box',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 2,
    ...fluidValues({
      paddingTop: [24, 90],
      paddingLeft: [24, 80],
      paddingRight: [24, 80],
      paddingBottom: [48, 90]
    }),
    color: colors.colorConsole500,
    borderColor: colors.colorConsole500,

    extend: {
      condition: props.dark,
      style: {
        color: '#FFFFFF',
        borderColor: '#FFFFFF',
      }
    },
  }
})


SignupForm.defaultProps = {
  cmListID: '',
  dark: false,
  image: null,
  rules: {},
  styles: {},
  thankYouMessage: '',
  title: '',
}

SignupForm.propTypes = {
  cmListID: PropTypes.string,
  dark: PropTypes.bool,
  image: PropTypes.object,
  rules: PropTypes.object,
  styles: PropTypes.object,
  thankYouMessage: PropTypes.string,
  title: PropTypes.string,
}

export default connectFela(styles)(SignupForm)
