import React from "react"
import { connect as connectFela } from "react-fela"

import colors from "theme/colors"
import fluidValues from "utilities/fluidValues"
import fluidFonts from "utilities/fluidFonts"
import LoadingAnim from "components/LoadingAnim"

const SubmitButton = ({ rules, styles, name, loading, dark }) => (<div className={styles.container} >
  {!loading && <input className={styles.styles} type={"submit"} value={name} />}
  {loading && <LoadingAnim extend={{styles: rules.loading}}/>}
  </div>
)

/*
 */
const styles = (props) => ({
  container: {
    ...fluidValues({
      marginTop: [7, 53]
    })
  },
  styles: {
    cursor: 'pointer',
    ...fluidFonts({fontSize: [12, 18], lineHeight: [18, 24]}),
    extend: {
      ...fluidValues({minWidth: [197, 248], paddingTop: [14, 24], paddingBottom: [14, 24]}),
    },
    border: `1px solid ${colors.colorConsole500}`,
    borderRadius: 2,
    display: 'inline-block',
    paddingLeft: 24,
    paddingRight: 24,
    position: 'relative',
    textAlign: 'center',
    textTransform: 'uppercase',

    onAfter: {
      backgroundColor: colors.colorConsole500,
      bottom: -7,
      content: "''",
      display: 'block',
      height: 6,
      left: 0,
      marginLeft: '5%',
      position: 'absolute',
      width: '90%',
    },

    onFocus: {
      onAfter: {
        transition: 'background-color 0.1s cubic-bezier(.17,.67,.75,1)',
        backgroundColor: colors.colorPattern50,
      }
    },

    onHover: {
      onAfter: {
        transition: 'background-color 0.1s cubic-bezier(.17,.67,.75,1)',
        backgroundColor: colors.colorPattern50,
      }
    },

    onActive: {
      transition: 'transform 0.03s cubic-bezier(.17,.67,.75,1)',
      transform: 'translateY(6px)',
      backgroundColor: colors.colorConsole500,
      color: '#FFFFFF',

      onAfter: {
        height: 0,
      }
    },

    onDisabled: {
      color: colors.colorPattern300,
      backgroundColor: colors.colorPattern50,
      transform: 'translateY(6px)',
    },
  },
  loading: {
    margin: '0 auto',
    display: 'block',
    extend: {
      condition: props.dark,
      style: {
        fill: '#FFFFFF',
      }
    }
  }
})

export default connectFela(styles)(SubmitButton)
